import React, { useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, Select, TextField } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { FormHelperText } from '@mui/material';
import { getNames } from 'country-list';

import { ActionButton } from 'components/Button/ActionButton';
import { upFChar } from 'utils/textFormatting';

import styles from '../Login.module.scss';

export interface Errors {
  firstname?: string;
  lastname?: string;
  email?: string;
  country?: string;
}

export default function Signup({ isLoading, signUpAction, setFormToDisplay, organizations }) {
  const { t } = useTranslation();

  const onSubmit = (values) => {
    const userData = {};
    // We make sure not to replace with empty
    Object.keys(values).forEach((key) => {
      if (values[key] !== '') {
        userData[key] = values[key];
      }
    });

    signUpAction(userData);
  };

  const submitRef = useRef();

  return (
    <div>
      <div className={styles.arrowBack} onClick={() => setFormToDisplay('login')}>
        <ArrowBack />
      </div>
      {/* <h1>{upFChar(t('auth.welcomeSecbio'))}</h1> */}
      <p>{upFChar(t('auth.welcomeSignUpText'))}</p>
      <Form
        onSubmit={(values) => onSubmit(values)}
        validate={(values) => {
          const errors: Errors = {};
          if (!values.firstname) {
            errors.firstname = 'First name required';
          }
          if (!values.lastname) {
            errors.lastname = 'Last name required';
          }
          if (!values.email) {
            errors.email = 'Email required';
          }
          if (!values.country) {
            errors.country = 'Country required';
          }
          return errors;
        }}>
        {(props) => (
          <form onSubmit={props.handleSubmit} noValidate className={styles.form}>
            <div className={styles.row}>
              <Field name="firstname">
                {(props) => (
                  <div>
                    <label>{upFChar(t('components.formAddUser.sections.firstName'))}</label>
                    <TextField
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      placeholder={upFChar(t('components.formAddUser.sections.firstName'))}
                      style={{
                        width: '-webkit-fill-available',
                        marginTop: 6,
                        marginBottom: 18,
                      }}
                      margin="normal"
                      fullWidth
                      variant={'outlined'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      required
                      error={props.meta.error && props.meta.touched}
                      helperText={props.meta.error && props.meta.touched && props.meta.error}
                    />
                  </div>
                )}
              </Field>
              <Field name="lastname">
                {(props) => (
                  <div style={{ marginLeft: 16 }}>
                    <label>{upFChar(t('components.formAddUser.sections.lastName'))}</label>
                    <TextField
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      placeholder={upFChar(t('components.formAddUser.sections.lastName'))}
                      style={{
                        width: '-webkit-fill-available',
                        marginTop: 6,
                        marginBottom: 18,
                      }}
                      margin="normal"
                      fullWidth
                      required
                      variant={'outlined'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={props.meta.error && props.meta.touched}
                      helperText={props.meta.error && props.meta.touched && props.meta.error}
                    />
                  </div>
                )}
              </Field>
            </div>

            <Field name="email">
              {(props) => (
                <div>
                  <label>{upFChar(t('components.formAddUser.sections.email'))}</label>
                  <TextField
                    name={props.input.name}
                    value={props.input.value}
                    onChange={props.input.onChange}
                    placeholder={upFChar(t('components.formAddUser.sections.email'))}
                    style={{
                      width: '-webkit-fill-available',
                      marginTop: 6,
                      marginBottom: 18,
                    }}
                    margin="normal"
                    fullWidth
                    required
                    variant={'outlined'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={props.meta.error && props.meta.touched}
                    helperText={props.meta.error && props.meta.touched && props.meta.error}
                  />
                </div>
              )}
            </Field>

            <Field name="occupation">
              {(props) => (
                <div>
                  <label> {upFChar(t('components.formAddUser.sections.occupation'))}</label>
                  <TextField
                    name={props.input.name}
                    value={props.input.value}
                    onChange={props.input.onChange}
                    style={{
                      width: '-webkit-fill-available',
                      marginTop: 6,
                      marginBottom: 18,
                    }}
                    placeholder={upFChar(t('components.formAddUser.sections.occupation'))}
                    margin="normal"
                    fullWidth
                    variant={'outlined'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    required={false}
                    error={props.meta.error && props.meta.touched}
                    helperText={props.meta.error && props.meta.touched && props.meta.error}
                  />
                </div>
              )}
            </Field>

            <div className={styles.row}>
              <Field name="organization">
                {(props) => (
                  <div style={{ width: '100%' }}>
                    <label> {upFChar(t('components.formAddUser.sections.organization'))}</label>
                    <FormHelperText id="my-helper-text">
                      {upFChar(t('components.formAddUser.sections.organization-helper'))}
                    </FormHelperText>

                    <FormControl
                      variant={'outlined'}
                      fullWidth
                      margin="normal"
                      style={{
                        width: '-webkit-fill-available',
                        marginTop: 6,
                        marginBottom: 18,
                      }}>
                      <Select
                        labelId="organization_label"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.value);
                        }}
                        fullWidth
                        required={false}
                        error={props.meta.error && props.meta.touched}>
                        {organizations.map((organization, index) => (
                          <MenuItem key={index} value={organization.name}>
                            {organization.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </Field>
            </div>

            <div className={styles.row}>
              <Field name="country">
                {(props) => (
                  <div style={{ width: '100%' }}>
                    <label> {upFChar(t('components.formAddUser.sections.country'))}</label>

                    <FormControl
                      variant={'outlined'}
                      fullWidth
                      style={{
                        width: '-webkit-fill-available',
                        marginTop: 6,
                        marginBottom: 18,
                      }}>
                      <Select
                        labelId="country_label"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={(e) => {
                          props.input.onChange(e.target.value);
                        }}
                        fullWidth
                        required={false}
                        error={props.meta.error && props.meta.touched}>
                        {getNames().map((name, index) => (
                          <MenuItem key={index} value={name.toLowerCase()}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </Field>
            </div>
            <div style={{ width: '100%' }}>
              <input ref={submitRef} type="submit" style={{ visibility: 'hidden' }} />
              <ActionButton
                type="fill"
                color="primary"
                isDisabled={isLoading}
                onClick={() =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  submitRef?.current?.click()
                }>
                {t('auth.signUpAction')}
              </ActionButton>
            </div>
          </form>
        )}
      </Form>
      <div className={styles.signUp}>
        {t('auth.loginPre')}{' '}
        <p
          className={styles.signUpCTA}
          onClick={() => setFormToDisplay('login')}
          // setModalContent({
          //   properties: {
          //     size: 'large',
          //     type: 'signUpUser',
          //   },
          //   header: {
          //     title: upFChar(t('pages.dashboard.allUsers.modal.signUpUser.title')),
          //     subTitle: upFChar(t('pages.dashboard.allUsers.modal.signUpUser.subtitle')),
          //   },
          //   data: {
          //     action: () => console.log('update'),
          //     userData: null,
          //   } as ModalUpdateUserContent,
          // })
        >
          {upFChar(t('auth.loginAction'))}
        </p>
      </div>
    </div>
  );
}
